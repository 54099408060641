import { defineStore } from 'pinia'
import authApi from '../api/auth'

interface User {
  email: string
  role: string
  _id: string
  created: string
  deleted: boolean
  name: string
}

interface LoginParams {
  email: string
  password: string
}

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      isAuthenticated: false,
      user: null as User | null,
    }
  },

  actions: {
    async login(params: LoginParams) {
      try {
        const { data } = await authApi.login(params)
        this.isAuthenticated = true
        this.user = { ...data.user }
        return data
      } catch (error) {
        return error
      }
    },

    async logout() {
      try {
        await authApi.logout()
        this.isAuthenticated = false
        this.user = null
      } catch (error) {
        return error
      }
    },

    async getMe() {
      try {
        const { data } = await authApi.getMe()
        this.isAuthenticated = true
        this.user = { ...data.user }
        return data
      } catch (error) {
        return error
      }
    },

    async editMe(user: User) {
      try {
        const { data } = await authApi.editUser(user)
        this.user = { ...data.user }
        return data
      } catch (error) {
        return error
      }
    },
  },
})
