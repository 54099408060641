<template>
  <svg class="logo" width="64" height="34" viewBox="0 0 64 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.872 1.28H38.216L40.008 7H38.088L37.824 6.048H36.016L35.76 7H34.072L35.872 1.28ZM36.36 4.816H37.488L36.928 2.744L36.36 4.816ZM42.8781 5.608C43.3261 5.608 43.6488 5.49333 43.8461 5.264C44.0435 5.03467 44.1421 4.66933 44.1421 4.168V4.104C44.1421 3.58667 44.0408 3.21867 43.8381 3C43.6355 2.78133 43.3128 2.672 42.8701 2.672H42.5741V5.608H42.8781ZM40.7581 1.28H42.9421C43.9661 1.28 44.7368 1.51733 45.2541 1.992C45.7715 2.46133 46.0301 3.15467 46.0301 4.072V4.128C46.0301 5.05067 45.7661 5.76 45.2381 6.256C44.7155 6.752 43.9501 7 42.9421 7H40.7581V1.28ZM46.9259 1.28H49.2779L50.2459 4.056L51.2299 1.28H53.5339V7H51.7339V3.624L50.4299 7H49.8219L48.5419 3.624V7H46.9259V1.28ZM54.774 1.28H56.606V7H54.774V1.28ZM57.8397 1.28H59.8717L61.4957 4.304V1.28H63.0877V7H61.3517L59.4397 3.576V7H57.8397V1.28Z"
      :fill="fill"
    />
    <path
      d="M16.3542 21.9892C14.2484 29.8486 12.0833 33.7932 3.20041 33.7932C2.39962 33.7932 1.584 33.7339 0.783203 33.7042L2.51826 27.2239C2.75553 27.298 3.00763 27.3128 3.28939 27.3128C6.107 27.3128 6.70018 24.6584 7.30819 22.4192L10.7635 9.62163H19.6612L16.3542 21.9892ZM33.1264 9.17676C29.5228 9.17676 25.1926 9.59197 22.0042 11.4011L19.4091 21.0401C21.3814 22.3896 24.2435 23.0272 26.5865 23.5462C27.6394 23.7687 33.0374 24.5843 33.0374 26.0227C33.0374 27.6984 29.5821 28.1581 28.0398 28.1581C26.72 28.1581 24.2731 27.6539 24.1842 25.9337H18.1041L16.6804 31.2722C18.9642 33.4966 23.5465 33.7932 26.5272 33.7932C32.2366 33.7932 42.2169 32.0433 42.2169 24.4211C42.2169 17.6739 26.9573 17.9111 26.9573 15.6868C26.9573 14.189 30.8723 14.0704 31.8065 14.0704C33.4674 14.0704 34.8614 14.1149 35.8698 15.5533H44.7675C44.8268 15.3309 44.8417 15.0936 44.8417 14.8712C44.8417 9.7551 36.6261 9.17676 33.1264 9.17676Z"
      :fill="fill"
    />
    <path
      d="M30.9669 0.794434H26.5185L25.7239 3.76048H30.1723L30.9669 0.794434ZM22.0701 0.794434H13.1733L11.584 6.72654H24.9292L25.7239 3.76048H21.2755L22.0701 0.794434Z"
      :fill="accentColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
    accentColor: {
      type: String,
      default: '#ED1A3B',
    },
  },
  setup(props) {
    return {
      fill: props.color,
      accentFill: props.accentColor,
    }
  },
})
</script>

<style lang="scss"></style>
