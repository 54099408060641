import axios from 'axios'

const instance = axios.create({
  withCredentials: true,
})

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    const { response, message } = err

    // FIX ME: Переделать на более универсальный способ

    if (response?.status === 413) {
      // Добавила response.statusText для больших картинок
      return Promise.reject(response.statusText || message || response.data?.error)
    } else {
      return Promise.reject(message || response?.data?.error)
    }
  },
)

export default instance
