import { checkAuth } from './middleware'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    meta: { requiresAuth: true },
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/dashboard/Dashboard.vue'),
      },
      {
        name: 'industries',
        path: 'industries',
        component: () => import('../pages/industries/Industries.vue'),
      },
      {
        name: 'tags',
        path: 'tags',
        component: () => import('../pages/tags/Tags.vue'),
      },
      {
        name: 'services',
        path: 'services',
        component: () => import('../pages/services/Services.vue'),
      },
      {
        name: 'cases',
        path: 'cases',
        component: () => import('../pages/cases/Cases.vue'),
      },
      {
        name: 'partners',
        path: 'partners',
        component: () => import('@/pages/partners/Partners.vue'),
      },
      {
        name: 'reviews',
        path: 'reviews',
        component: () => import('@/pages/reviews/Reviews.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/Users.vue'),
      },
      {
        name: 'banners',
        path: 'banners',
        component: () => import('../pages/banners/Banners.vue'),
      },
      {
        name: 'feedbacks',
        path: 'feedbacks',
        component: () => import('../pages/feedbacks/Feedbacks.vue'),
      },
      {
        name: 'awards',
        path: '/awards',
        redirect: { name: 'awards-list' },
        children: [
          {
            name: 'awards-list',
            path: 'awards-list',
            component: () => import('../pages/awards/Awards.vue'),
          },
          {
            name: 'achievements',
            path: 'achievements',
            component: () => import('../pages/awards/Achievements.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },

      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

// Проверка авторизации пользователя
router.beforeEach(checkAuth)

export default router
