import { useUserStore } from '../stores/user-store'

export const checkAuth = async (to: any, from: any, next: any) => {
  const requiresAuth = to.matched.some((route: any) => route.meta.requiresAuth)

  const userStore = useUserStore()
  const { isAuthenticated } = userStore

  if (!requiresAuth && isAuthenticated) next()

  const { user } = await userStore.getMe()
  if (user) return next()

  return next('/auth/login')
}
