import axios from './axios'

const login = (data) => {
  return axios({
    url: '/api/login',
    method: 'POST',
    data,
  })
}

const getMe = () => {
  return axios({
    url: '/api/me',
    method: 'GET',
  })
}

const logout = () => {
  return axios({
    url: '/api/logout',
    method: 'post',
  })
}

const registration = async (data) => {
  try {
    const res = await axios({
      url: '/api/registration',
      method: 'POST',
      data,
    })
    return res.data
  } catch (error) {
    return { error }
  }
}

const editUser = async (data) => {
  try {
    const res = await axios({
      url: '/api/user',
      method: 'PUT',
      data,
    })
    return res.data
  } catch (error) {
    return { error }
  }
}

export default {
  login,
  getMe,
  logout,
  registration,
  editUser,
}
