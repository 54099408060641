export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
  badge?: string
}

export default {
  root: {
    name: '/',
    displayName: '',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'Главная',
      meta: {
        icon: 'mso-home',
      },
    },

    {
      name: 'banners',
      displayName: 'Баннеры',
      meta: {
        icon: 'mso-view_array',
      },
    },

    {
      name: 'services',
      displayName: 'Услуги',
      meta: {
        icon: 'mso-dashboard',
      },
    },
    {
      name: 'industries',
      displayName: 'Отрасли',
      meta: {
        icon: 'mso-view_agenda',
      },
    },

    {
      name: 'tags',
      displayName: 'Теги',
      meta: {
        icon: 'mso-label',
      },
    },

    {
      name: 'cases',
      displayName: 'Кейсы',
      meta: {
        icon: 'mso-note_stack',
      },
    },
    {
      name: 'awards',
      displayName: 'Награды',
      meta: {
        icon: 'mso-emoji_events',
      },
      children: [
        {
          name: 'awards-list',
          displayName: 'Все',
        },
        {
          name: 'achievements',
          displayName: 'На главной',
        },
      ],
    },
    {
      name: 'partners',
      displayName: 'Партнеры',
      meta: {
        icon: 'mso-handshake',
      },
    },
    {
      name: 'reviews',
      displayName: 'Отзывы',
      meta: {
        icon: 'mso-reviews',
      },
    },
    {
      name: 'feedbacks',
      displayName: 'Входящие обращения',
      meta: {
        icon: 'mso-chat',
      },
    },

    {
      name: 'users',
      displayName: 'Пользователи',
      meta: {
        icon: 'mso-group',
      },
    },
  ] as INavigationRoute[],
}
